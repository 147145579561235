.ContentText {
    text-align: justify;
    font-size: 14px;
    line-height: 20px;
    font-family: "Lato Light", Arial, sans-serif;
    color: #000000;
    margin-bottom: 20px;
}

.ContentText p,
.ContentText ul {
    font-family: "Lato Regular", Arial, sans-serif;
}

.ContentText p:first-child {
    font-family: "Lato Light", "Arial Narrow", sans-serif;
}

.ContentText h2, h3 {
    font-family: "Lato Heavy", "Arial Bold", sans-serif;
    font-size: 24px;
    line-height: 30px;
    margin-left: 20px;
}

.ContentText h3 {
    font-size: 20px;
}

.ContentText h1,
.ContentText h2,
.ContentText h3,
.ContentText h4,
.ContentText h5 {
    text-align: left;
}

.ContentText li {
    list-style: none;
    position: relative;
    padding-left: 20px;
    padding-bottom: 10px;
    text-align: left;
}

.ContentText li:before {
    display: block;
    content: '';
    width: 6px;
    height: 6px;
    position: absolute;
    top: 10px;
    left: -5px;
    border-radius: 3px;
    background-color: #f5d300;
}

.ContentText p,
.ContentText i {
    text-indent: 20px;
    margin-bottom: 1em;
    display: block;
}

.ContentText img,
.ContentText video {
    display: block;
    max-width: 100%;
    height: auto;
    margin-top: 20px;
    width: 100%;
}

.ContentText p img {
    margin-bottom: 20px;
}

.ContentText video {
    margin-bottom: 20px;
}


@media (min-width: 720px) {
    .ContentText {
        font-size: 20px;
        line-height: 30px;
        color: #666;
        font-family: Arial, sans-serif;
        margin-bottom: 40px;
    }

    .ContentText p,
    .ContentText ul {
        font-family: inherit;
    }
}
.PressContentList-Item {
    background-color: #171C23;
    padding-top: 55px;
    position: relative;
    border-top: 5px solid #29313E;
}

.PressContentList-ItemTitle {
    max-width: calc(100% - 40px);
    display: inline-block;
    font-size: 26px;
    line-height: 1;
    padding: 0;
    margin: 0;
    text-align: center;
    background-color: #ffffff;
    font-family: "Lato Light", Arial, sans-serif;
    /*max-width: 745px;*/
    color: #171C23;
    position: absolute;
    top: 19px;
}

.PressContentList-ItemTitle span {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-height: 56px;
    padding: 15px 20px;
}

.PressContentList-ItemTitle:after {
    display: block;
    content: '';
    width: 22px;
    max-width: 22px;
    height: 36px;
    max-height: 36px;
    box-sizing: border-box;
    position: absolute;
    border-top: 18px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 18px solid #404F63;
    border-left: 11px solid #404F63;
    top: 0;
    left: 100%;
    z-index: 1;
}

@media (min-width: 720px) {
    .PressContentList {
        font-family: "Arial Narrow", Arial, sans-serif;
    }

    .PressContentList-ItemTitle {
        font-family: "Arial Narrow Bold", Arial, sans-serif;
    }

}
.Menu {
    color: #fff;
    min-width: 240px;
    font-family: "Arial Narrow", Arial, sans-serif;
    font-size: 22px;
    width: 100%;
    padding-top: 93px;
}

.Menu-Wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
 }

.Menu_visible {
    display: block;
}

.Menu-List {
    width: 50%;
    height: 100%;
    list-style: none;
    margin: 0;
    padding: 20px 0 20px 20px;
    background-color: #58595b;
}

.Menu-Link,
.Menu-Link:hover,
.Menu-Link:active {
    display: block;
    text-decoration: none;
    text-align: left;
    padding-left: 35px;
    height: 60px;
    line-height: 60px;
    color: inherit;
    position: relative;
}

.Menu-Item,
.Menu-Item:hover,
.Menu-Item:active {
    position: relative;
}

.Menu-Item_type_pressa-ru-ua-pdf:before {
    background-image: url('../Icons/press.svg');
}

.Menu-Item_type_family:before {
    background-image: url('../Icons/family.svg');
}

.Menu-Item_type_health:before {
    background-image: url('../Icons/heart.svg');
}

.Menu-Item_type_style:before {
    background-image: url('../Icons/style.svg');
}

.Menu-Item_type_beauty:before {
    background-image: url('../Icons/lips.svg');
}

.Menu-Item_type_diet:before {
    background-image: url('../Icons/meatball.svg');
}

.Menu-Item_type_sport:before {
    background-image: url('../Icons/gym.svg');
}

.Menu-Item:before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    content: '';
    display: block;
    width: 25px;
    height: 25px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}

@media (min-width: 1200px) {
    .Menu {
        width: auto;
        height: 60px;
        padding-top: 0;
        margin-left: auto;
        color: #58595b;
        font-size: 20px;
        font-family: "Lato Semibold", Arial, sans-serif;
        text-transform: uppercase;
    }

    .Menu-Wrapper {
        flex-direction: row;
        background-color: transparent;
    }

    .Menu-List {
        display: flex;
        flex-direction: row;
        margin: 0;
        padding: 0;
        height: 70px;
        background-color: transparent;
    }

    .Menu-Item {
        padding: 0 0 0 15px;
    }

    .Menu-Item:before {
        display: none;
    }

    .Menu-Link,
    .Menu-Link:hover,
    .Menu-Link:active {
        height: 70px;
        line-height: 70px;
        padding: 10px 0 0 0;
    }

    .Menu-ItemLink-Text {
        line-height: 50px;
    }

    .Menu-Link:hover .Menu-ItemLink-Text,
    .Menu-Link:active .Menu-ItemLink-Text {
        color: #E30611;
        border-bottom: 5px solid #E30611;
    }
}

.ContentPage {
}

.ContentPage-Content {
    margin-top: 93px;
}

.ContentPage-MainBar {
    padding: 0 10px;
}

.ContentPage-CategoryLink {
    height: 40px;
    min-width: 40px;
    color: inherit;
    text-decoration: none;
    display: inline-block;
    padding: 15px 0;
    text-align: left;
    position: relative;
}

.ContentPage-CategoryLink-Btn {
    text-decoration: none;
    display: block;
    height: 30px;
    width: 30px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px 30px;
}

.ContentPage-CategoryLink-Btn_size_L {
    display: none;
}

.ContentPage-CategoryLink-Btn_size_S {
    background-image: url('../Icons/back.svg');
}

.ContentPage-AdditionalBar {
    padding: 0 10px;
    background-color: #f6f6f6;
}

.ContentPage-Video {
    max-width: 100%;
    margin-bottom: 20px;
}

.ContentPage-Video[poster] {
    object-fit: cover;
}

.ContentPage-ContentAdditionalWrapper {
    display: flex;
    flex-direction: column;
}

.ContentPage-ContentAdditionalTitle {
    text-align: center;
    margin: 30px 0;
}

@media (min-width: 720px) {
    .ContentPage-Content {
        display: flex;
    }

    .ContentPage-Video {
        width: 96%;
        margin: 0 2% 20px;
    }

    .ContentPage-MainBar {
        width: 67vw;
        padding: 0 40px;
    }

    .ContentPage-AdditionalBar {
        overflow-y: scroll;
        position: relative;
        align-self: stretch;
        width: 33vw;
    }

    .ContentPage-ContentAdditionalWrapper {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
    }

    .ContentPage-ContentAdditionalTitle {
        display: none;
    }

    .ContentPage-CategoryLink {
        height: 90px;
        min-width: 90px;
    }

    .ContentPage-CategoryLink-Btn {
        height: 80px;
        width: 50px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 50px 50px;
    }

    .ContentPage-CategoryLink-Btn_size_L {
        display: block;
    }

    .ContentPage-CategoryLink-Btn .ContentPage-CategoryLink-Btn_size_S {
        display: none;
    }

}

.ContentItemPreview {
    position: relative;
    margin-bottom: 10px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
}

.ContentItemPreview_size_big.ContentItemPreview_type_sf_section .ContentItemPreview-Image,
.ContentItemPreview_size_big.ContentItemPreview_type_sf_any_content .ContentItemPreview-Image{
    width: 100%;
    height: calc(50vw - 10px);
}

.ContentItemPreview_size_big.ContentItemPreview_type_html .ContentItemPreview-Image,
.ContentItemPreview_size_common.ContentItemPreview_type_html .ContentItemPreview-Image {
    width: 100%;
    height: calc(100vw - 20px);
}

/*стили для size_small*/
.ContentItemPreview_size_small {
    position: relative;
    background-color: #ffffff;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);

}

.ContentItemPreview_size_small .ContentItemPreview-ImageWrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 40vw;
    height: 40vw;
    padding: 10px;
}

.ContentItemPreview_size_small .ContentItemPreview-Image {
    width: 100%;
    height: 100%;
}

.ContentItemPreview_size_small .ContentItemPreview-Description {
    margin-left: 40vw;
}

.ContentItemPreview_size_small:before {
    display: block;
    content: '';
    width: 100%;
    height: 30px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 0;
    background-color: #E30611;
}

/*стили для странцы контента*/
.ContentItemPreview_size_common .ContentItemPreview-Info {
    display: flex;
    flex-direction: column;
}

.ContentItemPreview_size_common .ContentItemPreview-ImageWrap {
    order: 1;
}

.ContentItemPreview_premium.ContentItemPreview_type_sf_section .ContentItemPreview-ImageWrap:after,
.ContentItemPreview_premium.ContentItemPreview_type_sf_any_content .ContentItemPreview-ImageWrap:after{
    position: absolute;
    content: '';
    display: block;
    top: 0;
    left: 0;
    width: 35px;
    height: 35px;
    background-color: rgba(0, 0, 0, 0.2);
    background-image: url('../Icons/cart.svg');
    background-position: center;
    background-size: 70%;
    background-repeat: no-repeat;
    z-index: 1;
}

.ContentItemPreview_size_small.ContentItemPreview_premium.ContentItemPreview_type_sf_section .ContentItemPreview-ImageWrap:after,
.ContentItemPreview_size_small.ContentItemPreview_premium.ContentItemPreview_type_sf_any_content .ContentItemPreview-ImageWrap:after{
    top: 10px;
    left: 10px;
}

.ContentItemPreview-Image {
    width: auto;
    height: 100%;
}

.ContentItemPreview.ContentItemPreview_ofPage_ContentPage {
    box-shadow: none;
}

@media (min-width: 720px) {
    .ContentItemPreview {
        margin-bottom: 0;
    }

    .ContentItemPreview-Link,
    .ContentItemPreview-Info,
    .ContentItemPreview-ImageWrap {
        display: block;
        height: 100%;
    }

    .ContentItemPreview-Info {
        position: relative;
    }

    .ContentItemPreview_type_sf_section.ContentItemPreview_size_big .ContentItemPreview-Description,
    .ContentItemPreview_type_sf_any_content.ContentItemPreview_size_big .ContentItemPreview-Description,
    .ContentItemPreview_type_html.ContentItemPreview_size_big .ContentItemPreview-Description{
        position: absolute;
        bottom: 20px;
        left: 20px;
        z-index: 1;
        width: calc(100% - 40px);
    }

    .ContentItemPreview_size_small .ContentItemPreview-ImageWrap {
        width: 45%;
        height: 100%;
    }


    .ContentItemPreview_size_small .ContentItemPreview-Description {
        margin-left: 45%;
    }

    .ContentItemPreview.ContentItemPreview_order_0.ContentListPartial-Item_size_big,
    .ContentItemPreview.ContentItemPreview_order_0.ContentListFull-Item_size_big {
        grid-column: span 2;
        grid-row: span 2;
    }

    .ContentItemPreview_type_html.ContentItemPreview_size_big {
        grid-column: span 1;
        grid-row: span 2;
    }

    .ContentListPartial-Item_size_small,
    .ContentListFull-Item_size_small {
        grid-column: span 1;
        grid-row: span 1;
        max-height: 190px;
    }

    .ContentItemPreview_size_big.ContentItemPreview_type_sf_section .ContentItemPreview-Image,
    .ContentItemPreview_size_big.ContentItemPreview_type_sf_any_content .ContentItemPreview-Image {
        height: 100%;
    }

    .ContentItemPreview_size_big.ContentItemPreview_type_html .ContentItemPreview-Image {
        height: 100%;
    }

    .ContentItemPreview_size_small .ContentItemPreview-Image {
        max-height: 170px;
    }

    .ContentItemPreview_size_common .ContentItemPreview-ImageWrap {
        display: none;
    }

}

@media (min-width: 720px) {
    .ContentItemPreview_ofPage_ContentPage.ContentAdditional-ContentItem.ContentItemPreview_size_big {
        height: 30vw;
    }

    .ContentItemPreview_ofPage_ContentPage.ContentAdditional-ContentItem.ContentItemPreview_size_small {
        height: 15vw;
        max-height: 190px;
    }

}

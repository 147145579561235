.ContentAdditional {
}

@media (min-width: 720px) {
    .ContentAdditional-ContentItem {
        margin-bottom: 20px;
    }

    .ContentAdditional {
        background-color: #f6f6f6;
        color: #fff;
        padding: 10px 20px;
    }
}

.ContentDescription {
    position: relative;
    font-family: Arial, sans-serif;
    color: #333333;
    max-height: 100%;
}

.ContentDescription_size_small {
    color: #58595b;
}

.ContentDescription-Title {
    background-color: #ffffff;
    font-size: 14px;
    line-height: 24px;
    position: relative;
}

.ContentDescription-Title:after {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    display: block;
    content: '';
    width: 40px;
    height: 20px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

.ContentDescription-Tags,
.ContentDescription-Type {
    font-family: Arial, sans-serif;
    font-size: 12px;
    color: #fff;
}

/*стили для контента size_big*/
.ContentDescription_size_big .ContentDescription-LabelWrap,
.ContentDescription_size_common .ContentDescription-LabelWrap {
    position: absolute;
    bottom: 100%;
    left: 0;
    z-index: 1;
    display: flex;
}

.ContentDescription_size_common .ContentDescription-LabelWrap {
    left: 50px;
    bottom: calc(100% + 10px);
}

.ContentDescription_size_big .ContentDescription-Title {
    display: block;
    font-size: 18px;
    line-height: 20px;
    padding: 20px;
}

.ContentDescription_size_big .ContentDescription-Tags,
.ContentDescription_size_big .ContentDescription-Type,
.ContentDescription_size_common .ContentDescription-Tags,
.ContentDescription_size_common .ContentDescription-Type {
    text-transform: uppercase;
    padding: 5px 10px;
    display: inline-block;
}

.ContentDescription_size_big .ContentDescription-Type,
.ContentDescription_size_common .ContentDescription-Type {
    color: #ffffff;
    background-color: #adafaf;
    order: 0;
}

.ContentDescription_size_big .ContentDescription-Tags,
.ContentDescription_size_common .ContentDescription-Tags {
    background-color: #E30611;
    order: 1;
}

/*стили для контента size_small*/
.ContentDescription_size_small {
    height: 40vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.ContentDescription_size_small .ContentDescription-Title {
    margin-bottom: 15px;
    overflow: hidden;
    margin-right: 20px;
    max-height: 150px;
}

.ContentDescription_size_small .ContentDescription-TagsWrap {
    height: 30px;
    white-space: nowrap;
    position: relative;
    margin-right: 10px;
    overflow: hidden;
}

.ContentDescription_size_small .ContentDescription-TagsWrap:after {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    display: block;
    content: '';
    width: 40px;
    height: 30px;
    background: linear-gradient(to right, rgba(227, 6, 17, 0), rgba(227, 6, 17, 1));
}

.ContentDescription_size_small .ContentDescription-Tags {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 5px;
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    font-family: Arial, sans-serif;
    text-transform: uppercase;
    color: #fff;
}

.ContentDescription_size_small .ContentDescription-Type {
    height: 30px;
    line-height: 30px;
    color: #adafaf;
}

/*стили для контента на странице контента*/
.ContentDescription_ofPage_ContentPage.ContentDescription_size_common .ContentDescription-Title{
    font-size: 24px;
    line-height: 30px;
    font-family: "Lato Heavy", "Arial Bold", sans-serif;
    margin-bottom: 20px;
}

@media (min-width: 720px) {
    .ContentDescription_ofPage_ContentPage.ContentDescription_size_common .ContentDescription-Title{
        font-size: 32px;
        line-height: 40px;
        font-family: "Arial Bold", sans-serif;
        margin-bottom: 20px;
    }

    .ContentDescription_size_common .ContentDescription-LabelWrap {
        left: 100px;
        bottom: calc(100% + 28px);
    }

    .ContentDescription_size_common .ContentDescription-Tags,
    .ContentDescription_size_common .ContentDescription-Type {
        font-family: Arial, sans-serif;
        font-size: 14px;
        line-height: 30px;
        padding: 5px 10px;
        display: inline-block;
    }
}

.SubscriptionPage {
    margin-top: 93px;
    padding: 10px;
    font-family: "Lato Light", Arial, sans-serif;
    flex-grow: 1;
    display: flex;
}

.SubscriptionPage-Text {
    flex-grow: 1;
    max-width: 1300px;
    margin: 0 auto;
}

.SubscriptionPage-Text a,
.SubscriptionPage-Text a:hover,
.SubscriptionPage-Text a:active {
    display: inline;
    color: inherit;
}

@media (min-width: 720px) {
    .SubscriptionPage {
        font-size: 20px;
    }
}
.CategoryItem {
    display: flex;
    flex-direction: column;
}

.CategoryItem-ContentWrap {
    padding: 0 10px;
}

.CategoryItem-HomeLink_ofPage_main {
    display: none;
}

.CategoryItem-Title {
    margin: 30px 0;
    font-size: 30px;
    text-transform: uppercase;
}

/*разные фоны категорий */

.CategoryItem_theme_dark,
.CategoryItem:nth-child(7) {
    background-color: #272f3b;
}

.CategoryItem:nth-child(even) {
     background-color: #fff;
 }

.CategoryItem:nth-child(3) {
    background-color: #aac613;
}

.CategoryItem:nth-child(5) {
    background-color: #f08c00;
}

.CategoryItem-TitleLink,
.CategoryItem-TitleLink:hover,
.CategoryItem-TitleLink:active {
    text-decoration: none;
    color: #4c4c4e;
    font-family: "Arial Bold" , sans-serif;
    text-align: center;
}

.CategoryItem:nth-child(even) .CategoryItem-TitleLink,
.CategoryItem:nth-child(even) .CategoryItem-TitleLink:hover,
.CategoryItem:nth-child(even) .CategoryItem-TitleLink:active {
    color: #58595b;
}

.CategoryItem:nth-child(odd) .CategoryItem-TitleLink,
.CategoryItem:nth-child(odd) .CategoryItem-TitleLink:hover,
.CategoryItem:nth-child(odd) .CategoryItem-TitleLink:active {
    color: #fff;
}

.CategoryItem_theme_dark .CategoryItem-TitleLink,
.CategoryItem_theme_dark .CategoryItem-TitleLink:hover,
.CategoryItem_theme_dark .CategoryItem-TitleLink:active {
    color: #fff;
}

/*кнопка больше*/

.CategoryItem-MoreButtonWrapper {
    padding: 20px 0 30px;
}

.CategoryItem-MoreButtonLink,
.CategoryItem-MoreButtonLink:hover,
.CategoryItem-MoreButtonLink:active {
    display: block;
    text-align: center;
    text-decoration: none;
    font-family: Arial, sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: inherit;
}

.CategoryItem-MoreButton {
    padding: 20px;
    text-align: center;
    min-width: 220px;
    max-width: 320px;
    width: 70%;
    margin: 0 auto;
}

.CategoryItem:nth-child(even) .CategoryItem-MoreButton {
    background-color: #E30611;
    color: #fff;
}

.CategoryItem:nth-child(odd) .CategoryItem-MoreButton {
    background-color: #fff;
    color: #58595b;
}

/*категория на странице категорий */
.CategoryItem_ofPage_category {
    background-color: #f6f6f6;
}

.CategoryItem_ofPage_category.CategoryItem_theme_dark {
    background-color: #272f3b;
}

.CategoryItem_ofPage_category.CategoryItem_theme_dark .CategoryItem-HomeLink-Btn_size_S {
    /*filter: invert(100%);*/
}

.CategoryItem_ofPage_category .CategoryItem-Title {
    color: #4c4c4e;
}

.CategoryItem_ofPage_category.CategoryItem_theme_dark .CategoryItem-Title {
    color: #ffffff;
}

.CategoryItem_ofPage_category .CategoryItem-MoreButtonWrapper {
    display: none;
}

.CategoryItem-LinkWrap {
    position: relative;
}

/*кнопка домой*/
.CategoryItem-HomeLink_ofPage_category,
.CategoryItem-HomeLink_ofPage_category:hover,
.CategoryItem-HomeLink_ofPage_category:active {
    text-decoration: none;
    display: block;
    height: 30px;
    width: auto;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 1;
    transform: translateY(-50%);
}

.CategoryItem-HomeLink-Btn_size_S {
    height: 30px;
    width: 30px;
    background-image: url('../Icons/back.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 25px 25px;
}

.CategoryItem-HomeLink-Btn_size_L {
    display: none;
}

@media (min-width: 720px) {
    .CategoryItem-MoreButtonWrapper {
        padding: 10px 0 30px;
    }

    .CategoryItem-LinkWrap {
        max-width: 1280px;
        width: 100%;
        margin: 0 auto;
    }

    .CategoryItem-HomeLink.CategoryItem-HomeLink_ofPage_category {
        display: none;
    }

    .CategoryItem_theme_dark.CategoryItem_ofPage_main .CategoryItem-MoreButtonWrapper {
        padding: 0 0 30px;
    }

    .CategoryItem-HomeLink-Btn_size_S {
        display: none;
    }

    .CategoryItem-HomeLink-Btn_size_L {
        display: block;
        text-transform: lowercase;
        font-family: "Arial Narrow Bold", Arial, sans-serif;
        font-size: 20px;
    }
}


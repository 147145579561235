.PressContentSlider {
    /*background-color: #272f3b;*/
    height: 75vw;
    padding-top: 30px;
    max-height: 500px;
}

.PressContentSlider_ofPage_MainPage {
    padding-top: 0;
    margin-bottom: 10px;
}

.swiper-container {
    height: 100%;
}

.PressContentSlider .swiper-container {
    height: 100%;
}

.PressContentSlider .swiper-button-prev,
.PressContentSlider .swiper-button-next {
    background-color: #fff;
    background-size: 25%;
    box-shadow:0 0 20px #fff;
}

@media (min-width: 720px){
    .PressContentSlider {
        height: 50vw;
        margin-bottom: 30px;
    }
}

@media (min-width: 1020px){
    .PressContentSlider {
        height: 26vw;
        margin-bottom: 30px;
    }
}

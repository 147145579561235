.ContentListFull-ItemsWrapper {
    display: flex;
    flex-direction: column;
}

.ContentListFull-Item_type_music {
    margin-bottom: 10px;
    height: calc(100vw - 20px);
}

/*изменение порядка выведения music card на мобильной версии*/
.ContentListFull-ItemsWrapper .ContentListFull-Item:nth-child(3) ~.ContentListFull-Item {
    order: 2;
}

.ContentListFull-ItemsWrapper .ContentListFull-Item:nth-child(8).ContentListFull-Item_type_music {
    order: 1;
}

@media(min-width: 720px) {
    .ContentListFull-ItemsWrapper .ContentListFull-Item:nth-child(3) ~.ContentListFull-Item {
        order: 0;
    }

    .ContentListFull-ItemsWrapper .ContentListFull-Item:nth-child(8).ContentListFull-Item_type_music {
        order: 0;
    }

    .ContentListFull-ItemsWrapper {
        overflow: hidden;
        display: grid;
        max-width: 840px;
        width: 100%;
        margin: 0 auto;
        padding: 0 20px 40px 20px;
        grid-gap: 40px;
        grid-template-columns: repeat(2, minmax(300px, 400px));
        grid-template-rows: repeat(12, 190px);
        grid-auto-flow: dense;
    }
}

@media (min-width: 1020px) {
    .ContentListFull-ItemsWrapper {
        max-width: 1300px;
        grid-template-columns: repeat(3, minmax(300px, 400px));
        grid-template-rows: repeat(7, 190px);
    }
}


@font-face {
  font-family: "Lato Heavy";
  font-style: normal;
  font-weight: 700;
  src: local("Lato Heavy"), url(./fonts/Lato-Heavy.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Lato Light";
  font-style: normal;
  font-weight: 300;
  src: local("Lato Light"), url(./fonts/Lato-Light.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Lato Regular";
  font-style: normal;
  font-weight: 400;
  src: local("Lato Regular"), url(./fonts/Lato-Regular.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Lato Semibold";
  font-style: normal;
  font-weight: 400;
  src: local("Lato Semibold"), url(./fonts/Lato-Semibold.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Arial Narrow";
  font-style: normal;
  font-weight: 400;
  src: local("Arial Narrow"), url(./fonts/Arial-Narrow.woff2) format("woff2");
  font-display: swap;
}


@font-face {
  font-family: "Arial Narrow Bold";
  font-style: normal;
  font-weight: 400;
  src: local("Arial Narrow Bold"), url(./fonts/Arial-Narrow-Bold.woff2) format("woff2");
  font-display: swap;
}

* {
  -webkit-tap-highlight-color: transparent !important;
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
}

body {
  padding: 0;
  font-family: "Lato Regular", Arial, sans-serif;
  line-height: 1;
  overflow-x: hidden;
  min-width: 320px;
  color: #4c4c4e;
  margin: 0 auto;
  position: relative;
}

button,
a {
  cursor: pointer;
  display: block;
}

a {
  text-decoration: none;
}

.Wrapper {
  max-width: 1280px;
  width: 100%;
}

/*swiper*/
.swiper-button-next,
.swiper-button-prev {
  background-image: url('./Components/Icons/NextArrowRed.svg');
  width: 60px;
  height: 60px;
  background-position: center;
  border-radius: 30px;
  cursor: pointer;
  outline: none;
}

.swiper-button-prev {
  transform: scale(-1, 1);
}

@media (min-width: 720px) {
  body {
    font-family: "Arial Narrow", Arial, sans-serif;
  }
}



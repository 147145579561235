.Footer {
    font-family: "Lato Light", Arial, sans-serif;
    border-top: 20px solid #333333;
    background-color: #58595b;
    color: #adafaf;
    padding: 20px 10px;
}

.Footer-OfferLink,
.Footer-OfferLink:hover,
.Footer-OfferLink:active,
.Footer-SubscriptionLink,
.Footer-SubscriptionLink:hover,
.Footer-SubscriptionLink:active {
    text-transform: uppercase;
    text-decoration: underline;
    color: inherit;
    display: block;
    margin: 10px 0;
}

.Footer p {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
}

.Footer-CategoriesLinkWrap {
    padding: 20px 0;
    font-family: "Lato Light", Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Footer-Link,
.Footer-Link:hover,
.Footer-Link:active {
    color: inherit;
    font-size: 20px;
    line-height: 40px;
    height: 40px;
    width: 220px;
    text-decoration: none;
    margin-bottom: 10px;
    border: 1px solid #adafaf;
    text-align: center;
}

.Footer-Link,
.Footer-Link:active {
    border: 1px solid #adafaf;
}

.Footer-Link:hover {
    border: 1px solid #E30611;
    color: #E30611;
}

.Footer .TextLink,
.Footer .TextLink:hover,
.Footer .TextLink:active,
.Footer-Text a,
.Footer-Text a:hover,
.Footer-Text a:active {
    display: inline;
    color: inherit;
}

.Footer-Text {
    text-align: justify;
    hyphens: auto;
}

.Footer-LogoContainer {
    display: flex;
    justify-content: center;
}
.Footer-LogoLink,
.Footer-LogoLink:hover,
.Footer-LogoLink:active {
    height: 18px;
    margin-right: 10px;
    filter: opacity(0.3);
}

.Footer-Logo {
    height: 18px;
}

.Footer .HomeLink {
    text-decoration: none;
}

@media (min-width: 470px) {
    .Footer-CategoriesLinkWrap {
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
    }

    .Footer-CategoryLink {
        margin-right: 5px;
        margin-left: 5px;
    }

}

@media (min-width: 720px) {
    .Footer {
        font-family: "Arial Narrow", Arial, sans-serif;
    }

    .Footer * {
        font-family: inherit;
    }

    .Footer-Wrapper {
        max-width: 1280px;
        margin: 0 auto;
    }

}

@media (min-width: 1080px) {
    .Footer-Link,
    .Footer-Link:hover,
    .Footer-Link:active {
        width: 140px;
    }
}

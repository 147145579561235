.ContentListPartial-ItemsWrapper {
    display: flex;
    flex-direction: column;
}

.ContentListPartial-ItemsWrapper .ContentListPartial-Item:nth-child(8) {
    order: 1
}

.ContentListPartial-ItemsWrapper .ContentListPartial-Item:nth-child(4),
.ContentListPartial-ItemsWrapper .ContentListPartial-Item:nth-child(5) {
    order: 2
}

.ContentListPartial-ItemsWrapper .ContentListPartial-Item:nth-child(6),
.ContentListPartial-ItemsWrapper .ContentListPartial-Item:nth-child(7) {
    display: none;
}

.ContentListPartial-Item_type_music.ContentListPartial-Item_size_big {
    margin-bottom: 10px;
    height: calc(100vw - 20px);
}

@media (min-width: 720px) {
    .ContentListPartial-ItemsWrapper {
        overflow: hidden;
        display: grid;
        max-width: 840px;
        width: 100%;
        margin: 0 auto;
        grid-gap: 40px;
        grid-template-columns: repeat(2, minmax(300px, 400px));
        grid-template-rows: repeat(6, 190px);
        grid-auto-flow: dense;
        padding: 0 20px 20px;
    }

    .ContentListPartial-ItemsWrapper .ContentListPartial-Item:nth-child(6):not(.ContentListPartial-Item_type_music),
    .ContentListPartial-ItemsWrapper .ContentListPartial-Item:nth-child(7):not(.ContentListPartial-Item_type_music) {
        display: block;
    }

    .ContentListPartial-Item_type_music.ContentListPartial-Item_size_big {
        grid-column: span 1;
        grid-row: span 2;
        margin-bottom: 0;
        height: auto;
    }
}

@media (min-width: 1020px) {
    .ContentListPartial-ItemsWrapper {
        max-width: 1300px;
        grid-template-columns: repeat(3, minmax(300px, 400px));
        grid-template-rows: repeat(4, 190px);
    }

    .ContentListPartial-Item_type_music.ContentListPartial-Item_size_big {
        grid-row: span 2;
    }

    .ContentListPartial-ItemsWrapper .ContentListPartial-Item:nth-child(6):not(.ContentListPartial-Item_type_music),
    .ContentListPartial-ItemsWrapper .ContentListPartial-Item:nth-child(7):not(.ContentListPartial-Item_type_music) {
        display: block;
    }
}

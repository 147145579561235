.MusicContentItem {
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    font-family: Arial, sans-serif;
}

.MusicContentItem-Type {
    background-color: #592e83;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    padding: 0 15px;
    line-height: 50px;
    width: 25%;
    text-align: center;
}

.MusicContentItem-ControlsWrapper {
    min-height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #29313E;
}

.MusicContentItem-Btn {
    width: 100px;
    height: 100px;
    background-repeat: no-repeat;
    background-size: 60%;
    cursor: pointer;
}

.MusicContentItem-Btn:hover,
.MusicContentItem-Btn:active {

}

.MusicContentItem-Btn_type_toggle {
    background-image: url("../Icons/play_btn.svg");
    background-position: center;
}


.MusicContentItem-Btn_type_toggle.MusicContentItem-Btn_isPlaying {
    background-image: url("../Icons/pause_btn.svg");
}

.MusicContentItem-Btn_type_prev,
.MusicContentItem-Btn_type_next {
    background-image: url("../Icons/next_track.svg");
    background-size: 50%;
    background-position: 60% center;
}

.MusicContentItem-Btn_type_prev {
    transform: scale(-1, 1);
}

.MusicContentItem-InfoWrapper {
    margin-top: auto;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    color: white;
    text-align: left;
    padding-left: 20px;
}
.MusicContentItem-PlayListName {
    font-size: 30px;
    margin: 20px 0 5px;
    font-weight: 700;
}

.MusicContentItem-TrackName {
    margin: 5px 0;
    font-size: 24px;
}


@media (min-width: 720px) {
    .MusicContentItem {
        grid-column: span 1;
        grid-row: span 2;
        height: auto;
    }
}



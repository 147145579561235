.PressContentItem {
    text-decoration: none;
    color: inherit;
    max-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.PressContentItem-ImageWrap {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    display: inline-block;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
    /*object-fit: contain;*/
}

.PressContentItem-Image {
    position: absolute;
    display: block;
    height: 100%;
    width: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.PressContentItem_premium .PressContentItem-ImageWrap:before {
    position: absolute;
    content: '';
    display: inline;
    right: 5px;
    bottom: 0;
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.2);
    background-image: url('../../Icons/cart.svg');
    background-position: center;
    background-size: 70%;
    background-repeat: no-repeat;
    z-index: 1;
}

.PressContentItem-Title {
    text-align: center;
    position: relative;
    margin-left: 15px;
    font-family: "Lato Regular", Arial, sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 100;
    color: #ffffff;
    letter-spacing: 1px;
}

@media (min-width: 500px) {
    .PressContentItem_premium .PressContentItem-ImageWrap:before {
        right: 0;
    }

}

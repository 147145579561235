.Header {
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    height: 93px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Header-Wrapper {
    height: 100%;
    display: flex;
    align-items: center;
}

.Header-Logo {
    max-height: 40px;
}

.Header-LogoContainer {
    margin-right: 20px;
}

.Header-ButtonWrapper {
    position: relative;
    height: 100%;
    width: 50px;
    min-width: 50px;
}

.Header-Select {
    cursor: pointer;
    position: relative;
    margin-left: auto;
    margin-right: 20px;
    width: 60px;
}

.Header-SelectList {
   display: none;
    padding: 0;
    list-style-type: none;
}

.Header-SelectList_visible {
    position: absolute;
    top: 100%;
    display: block;
    margin: 0;
    background-color: #fff;
    width: 60px;
}

.Header-SelectItem {
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    text-align: left;
    padding: 0 30px 0 10px;
}

.Header-SelectItem span {
    font-size: 16px;
}

.Header-SelectItem_state_selected {
    background-image: url("../Icons/down-arrow.svg");
    background-repeat: no-repeat;
    background-position: 85% 50% ;
}

.Header-SelectItem_active {
    display: none;
}


.Header-HamburgerButton,
.Header-CloseButton {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    opacity: 0;
    transition:  all cubic-bezier(0.17,0.67,1,-0.1) 0.5s;
}

.Header-HamburgerButton_visible,
.Header-CloseButton_visible {
    opacity: 1;
}

.Header-Menu {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    min-height: 100%;
    z-index: 1;
    transform: translateX(-100%);
    opacity: 0;
    transition: opacity ease-in-out 0.8s, transform ease-in-out 0.2s;
}

.Header-Menu_visible {
    transform: translateX(0);
    opacity: 1;
}

@media (min-width: 720px) {
    .Header-Wrapper {
        max-width: 1280px;
        margin: 0 auto;
    }
}

@media (min-width: 1200px) {
    .Header-Menu,
    .Header-Menu_visible {
        position: relative;
        transform: translateX(0);
        height: auto;
        top: auto;
        bottom: auto;
        left: auto;
        opacity: 1;
    }

    .Header-Select {
        margin-left: 30px;
    }

    .Header-ButtonWrapper {
        display: none;
    }

    .Header-LogoContainer {
        margin-left: 20px;
    }
}

.Header-PersonalCabinet {
    width: 100%;
    max-width: 1280px;
    font-size: 12px;
    padding: 5px 10px;
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px #4c4c4e solid;
}

.Header-PersonalCabinet a {
    color: #4c4c4e;
    text-decoration: underline;
}
.ContentListFull-ItemsWrapper {
    display: flex;
    flex-direction: column;
}

.ContentListFull-Item_type_music {
    margin-bottom: 10px;
    height: calc(100vw - 20px);
}

/*изменение порядка выведения music card на мобильной версии*/
.ContentListFull-ItemsWrapper .ContentListFull-Item:nth-child(3) ~.ContentListFull-Item {
    order: 2;
}

.ContentListFull-ItemsWrapper .ContentListFull-Item:nth-child(8).ContentListFull-Item_type_music {
    order: 1;
}

@media(min-width: 720px) {
    .ContentListFull-ItemsWrapper .ContentListFull-Item:nth-child(3) ~.ContentListFull-Item {
        order: 0;
    }

    .ContentListFull-ItemsWrapper .ContentListFull-Item:nth-child(8).ContentListFull-Item_type_music {
        order: 0;
    }

    .ContentListFull-ItemsWrapper {
        overflow: hidden;
        display: grid;
        max-width: 840px;
        width: 100%;
        margin: 0 auto;
        padding: 0 20px 40px 20px;
        grid-gap: 40px;
        grid-template-columns: repeat(2, minmax(300px, 400px));
        grid-template-rows: repeat(12, 190px);
        grid-auto-flow: dense;
    }
}

@media (min-width: 1020px) {
    .ContentListFull-ItemsWrapper {
        max-width: 1300px;
        grid-template-columns: repeat(3, minmax(300px, 400px));
        grid-template-rows: repeat(7, 190px);
    }
}


.ContentDescription {
    position: relative;
    font-family: Arial, sans-serif;
    color: #333333;
    max-height: 100%;
}

.ContentDescription_size_small {
    color: #58595b;
}

.ContentDescription-Title {
    background-color: #ffffff;
    font-size: 14px;
    line-height: 24px;
    position: relative;
}

.ContentDescription-Title:after {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    display: block;
    content: '';
    width: 40px;
    height: 20px;
    background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 1)));
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

.ContentDescription-Tags,
.ContentDescription-Type {
    font-family: Arial, sans-serif;
    font-size: 12px;
    color: #fff;
}

/*стили для контента size_big*/
.ContentDescription_size_big .ContentDescription-LabelWrap,
.ContentDescription_size_common .ContentDescription-LabelWrap {
    position: absolute;
    bottom: 100%;
    left: 0;
    z-index: 1;
    display: flex;
}

.ContentDescription_size_common .ContentDescription-LabelWrap {
    left: 50px;
    bottom: calc(100% + 10px);
}

.ContentDescription_size_big .ContentDescription-Title {
    display: block;
    font-size: 18px;
    line-height: 20px;
    padding: 20px;
}

.ContentDescription_size_big .ContentDescription-Tags,
.ContentDescription_size_big .ContentDescription-Type,
.ContentDescription_size_common .ContentDescription-Tags,
.ContentDescription_size_common .ContentDescription-Type {
    text-transform: uppercase;
    padding: 5px 10px;
    display: inline-block;
}

.ContentDescription_size_big .ContentDescription-Type,
.ContentDescription_size_common .ContentDescription-Type {
    color: #ffffff;
    background-color: #adafaf;
    order: 0;
}

.ContentDescription_size_big .ContentDescription-Tags,
.ContentDescription_size_common .ContentDescription-Tags {
    background-color: #E30611;
    order: 1;
}

/*стили для контента size_small*/
.ContentDescription_size_small {
    height: 40vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.ContentDescription_size_small .ContentDescription-Title {
    margin-bottom: 15px;
    overflow: hidden;
    margin-right: 20px;
    max-height: 150px;
}

.ContentDescription_size_small .ContentDescription-TagsWrap {
    height: 30px;
    white-space: nowrap;
    position: relative;
    margin-right: 10px;
    overflow: hidden;
}

.ContentDescription_size_small .ContentDescription-TagsWrap:after {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    display: block;
    content: '';
    width: 40px;
    height: 30px;
    background: -webkit-gradient(linear, left top, right top, from(rgba(227, 6, 17, 0)), to(rgba(227, 6, 17, 1)));
    background: -webkit-linear-gradient(left, rgba(227, 6, 17, 0), rgba(227, 6, 17, 1));
    background: linear-gradient(to right, rgba(227, 6, 17, 0), rgba(227, 6, 17, 1));
}

.ContentDescription_size_small .ContentDescription-Tags {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 5px;
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    font-family: Arial, sans-serif;
    text-transform: uppercase;
    color: #fff;
}

.ContentDescription_size_small .ContentDescription-Type {
    height: 30px;
    line-height: 30px;
    color: #adafaf;
}

/*стили для контента на странице контента*/
.ContentDescription_ofPage_ContentPage.ContentDescription_size_common .ContentDescription-Title{
    font-size: 24px;
    line-height: 30px;
    font-family: "Lato Heavy", "Arial Bold", sans-serif;
    margin-bottom: 20px;
}

@media (min-width: 720px) {
    .ContentDescription_ofPage_ContentPage.ContentDescription_size_common .ContentDescription-Title{
        font-size: 32px;
        line-height: 40px;
        font-family: "Arial Bold", sans-serif;
        margin-bottom: 20px;
    }

    .ContentDescription_size_common .ContentDescription-LabelWrap {
        left: 100px;
        bottom: calc(100% + 28px);
    }

    .ContentDescription_size_common .ContentDescription-Tags,
    .ContentDescription_size_common .ContentDescription-Type {
        font-family: Arial, sans-serif;
        font-size: 14px;
        line-height: 30px;
        padding: 5px 10px;
        display: inline-block;
    }
}

.ContentItemPreview {
    position: relative;
    margin-bottom: 10px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
}

.ContentItemPreview_size_big.ContentItemPreview_type_sf_section .ContentItemPreview-Image,
.ContentItemPreview_size_big.ContentItemPreview_type_sf_any_content .ContentItemPreview-Image{
    width: 100%;
    height: calc(50vw - 10px);
}

.ContentItemPreview_size_big.ContentItemPreview_type_html .ContentItemPreview-Image,
.ContentItemPreview_size_common.ContentItemPreview_type_html .ContentItemPreview-Image {
    width: 100%;
    height: calc(100vw - 20px);
}

/*стили для size_small*/
.ContentItemPreview_size_small {
    position: relative;
    background-color: #ffffff;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);

}

.ContentItemPreview_size_small .ContentItemPreview-ImageWrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 40vw;
    height: 40vw;
    padding: 10px;
}

.ContentItemPreview_size_small .ContentItemPreview-Image {
    width: 100%;
    height: 100%;
}

.ContentItemPreview_size_small .ContentItemPreview-Description {
    margin-left: 40vw;
}

.ContentItemPreview_size_small:before {
    display: block;
    content: '';
    width: 100%;
    height: 30px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 0;
    background-color: #E30611;
}

/*стили для странцы контента*/
.ContentItemPreview_size_common .ContentItemPreview-Info {
    display: flex;
    flex-direction: column;
}

.ContentItemPreview_size_common .ContentItemPreview-ImageWrap {
    order: 1;
}

.ContentItemPreview_premium.ContentItemPreview_type_sf_section .ContentItemPreview-ImageWrap:after,
.ContentItemPreview_premium.ContentItemPreview_type_sf_any_content .ContentItemPreview-ImageWrap:after{
    position: absolute;
    content: '';
    display: block;
    top: 0;
    left: 0;
    width: 35px;
    height: 35px;
    background-color: rgba(0, 0, 0, 0.2);
    background-image: url(/static/media/cart.e9366b05.svg);
    background-position: center;
    background-size: 70%;
    background-repeat: no-repeat;
    z-index: 1;
}

.ContentItemPreview_size_small.ContentItemPreview_premium.ContentItemPreview_type_sf_section .ContentItemPreview-ImageWrap:after,
.ContentItemPreview_size_small.ContentItemPreview_premium.ContentItemPreview_type_sf_any_content .ContentItemPreview-ImageWrap:after{
    top: 10px;
    left: 10px;
}

.ContentItemPreview-Image {
    width: auto;
    height: 100%;
}

.ContentItemPreview.ContentItemPreview_ofPage_ContentPage {
    box-shadow: none;
}

@media (min-width: 720px) {
    .ContentItemPreview {
        margin-bottom: 0;
    }

    .ContentItemPreview-Link,
    .ContentItemPreview-Info,
    .ContentItemPreview-ImageWrap {
        display: block;
        height: 100%;
    }

    .ContentItemPreview-Info {
        position: relative;
    }

    .ContentItemPreview_type_sf_section.ContentItemPreview_size_big .ContentItemPreview-Description,
    .ContentItemPreview_type_sf_any_content.ContentItemPreview_size_big .ContentItemPreview-Description,
    .ContentItemPreview_type_html.ContentItemPreview_size_big .ContentItemPreview-Description{
        position: absolute;
        bottom: 20px;
        left: 20px;
        z-index: 1;
        width: calc(100% - 40px);
    }

    .ContentItemPreview_size_small .ContentItemPreview-ImageWrap {
        width: 45%;
        height: 100%;
    }


    .ContentItemPreview_size_small .ContentItemPreview-Description {
        margin-left: 45%;
    }

    .ContentItemPreview.ContentItemPreview_order_0.ContentListPartial-Item_size_big,
    .ContentItemPreview.ContentItemPreview_order_0.ContentListFull-Item_size_big {
        grid-column: span 2;
        grid-row: span 2;
    }

    .ContentItemPreview_type_html.ContentItemPreview_size_big {
        grid-column: span 1;
        grid-row: span 2;
    }

    .ContentListPartial-Item_size_small,
    .ContentListFull-Item_size_small {
        grid-column: span 1;
        grid-row: span 1;
        max-height: 190px;
    }

    .ContentItemPreview_size_big.ContentItemPreview_type_sf_section .ContentItemPreview-Image,
    .ContentItemPreview_size_big.ContentItemPreview_type_sf_any_content .ContentItemPreview-Image {
        height: 100%;
    }

    .ContentItemPreview_size_big.ContentItemPreview_type_html .ContentItemPreview-Image {
        height: 100%;
    }

    .ContentItemPreview_size_small .ContentItemPreview-Image {
        max-height: 170px;
    }

    .ContentItemPreview_size_common .ContentItemPreview-ImageWrap {
        display: none;
    }

}

@media (min-width: 720px) {
    .ContentItemPreview_ofPage_ContentPage.ContentAdditional-ContentItem.ContentItemPreview_size_big {
        height: 30vw;
    }

    .ContentItemPreview_ofPage_ContentPage.ContentAdditional-ContentItem.ContentItemPreview_size_small {
        height: 15vw;
        max-height: 190px;
    }

}

.MusicContentItem {
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 1)));
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    font-family: Arial, sans-serif;
}

.MusicContentItem-Type {
    background-color: #592e83;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    padding: 0 15px;
    line-height: 50px;
    width: 25%;
    text-align: center;
}

.MusicContentItem-ControlsWrapper {
    min-height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #29313E;
}

.MusicContentItem-Btn {
    width: 100px;
    height: 100px;
    background-repeat: no-repeat;
    background-size: 60%;
    cursor: pointer;
}

.MusicContentItem-Btn:hover,
.MusicContentItem-Btn:active {

}

.MusicContentItem-Btn_type_toggle {
    background-image: url(/static/media/play_btn.fe3b20dc.svg);
    background-position: center;
}


.MusicContentItem-Btn_type_toggle.MusicContentItem-Btn_isPlaying {
    background-image: url(/static/media/pause_btn.6f34bd0b.svg);
}

.MusicContentItem-Btn_type_prev,
.MusicContentItem-Btn_type_next {
    background-image: url(/static/media/next_track.d433fac3.svg);
    background-size: 50%;
    background-position: 60% center;
}

.MusicContentItem-Btn_type_prev {
    -webkit-transform: scale(-1, 1);
            transform: scale(-1, 1);
}

.MusicContentItem-InfoWrapper {
    margin-top: auto;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    color: white;
    text-align: left;
    padding-left: 20px;
}
.MusicContentItem-PlayListName {
    font-size: 30px;
    margin: 20px 0 5px;
    font-weight: 700;
}

.MusicContentItem-TrackName {
    margin: 5px 0;
    font-size: 24px;
}


@media (min-width: 720px) {
    .MusicContentItem {
        grid-column: span 1;
        grid-row: span 2;
        height: auto;
    }
}



.ContentListPartial-ItemsWrapper {
    display: flex;
    flex-direction: column;
}

.ContentListPartial-ItemsWrapper .ContentListPartial-Item:nth-child(8) {
    order: 1
}

.ContentListPartial-ItemsWrapper .ContentListPartial-Item:nth-child(4),
.ContentListPartial-ItemsWrapper .ContentListPartial-Item:nth-child(5) {
    order: 2
}

.ContentListPartial-ItemsWrapper .ContentListPartial-Item:nth-child(6),
.ContentListPartial-ItemsWrapper .ContentListPartial-Item:nth-child(7) {
    display: none;
}

.ContentListPartial-Item_type_music.ContentListPartial-Item_size_big {
    margin-bottom: 10px;
    height: calc(100vw - 20px);
}

@media (min-width: 720px) {
    .ContentListPartial-ItemsWrapper {
        overflow: hidden;
        display: grid;
        max-width: 840px;
        width: 100%;
        margin: 0 auto;
        grid-gap: 40px;
        grid-template-columns: repeat(2, minmax(300px, 400px));
        grid-template-rows: repeat(6, 190px);
        grid-auto-flow: dense;
        padding: 0 20px 20px;
    }

    .ContentListPartial-ItemsWrapper .ContentListPartial-Item:nth-child(6):not(.ContentListPartial-Item_type_music),
    .ContentListPartial-ItemsWrapper .ContentListPartial-Item:nth-child(7):not(.ContentListPartial-Item_type_music) {
        display: block;
    }

    .ContentListPartial-Item_type_music.ContentListPartial-Item_size_big {
        grid-column: span 1;
        grid-row: span 2;
        margin-bottom: 0;
        height: auto;
    }
}

@media (min-width: 1020px) {
    .ContentListPartial-ItemsWrapper {
        max-width: 1300px;
        grid-template-columns: repeat(3, minmax(300px, 400px));
        grid-template-rows: repeat(4, 190px);
    }

    .ContentListPartial-Item_type_music.ContentListPartial-Item_size_big {
        grid-row: span 2;
    }

    .ContentListPartial-ItemsWrapper .ContentListPartial-Item:nth-child(6):not(.ContentListPartial-Item_type_music),
    .ContentListPartial-ItemsWrapper .ContentListPartial-Item:nth-child(7):not(.ContentListPartial-Item_type_music) {
        display: block;
    }
}

.PressContentSlider {
    /*background-color: #272f3b;*/
    height: 75vw;
    padding-top: 30px;
    max-height: 500px;
}

.PressContentSlider_ofPage_MainPage {
    padding-top: 0;
    margin-bottom: 10px;
}

.swiper-container {
    height: 100%;
}

.PressContentSlider .swiper-container {
    height: 100%;
}

.PressContentSlider .swiper-button-prev,
.PressContentSlider .swiper-button-next {
    background-color: #fff;
    background-size: 25%;
    box-shadow:0 0 20px #fff;
}

@media (min-width: 720px){
    .PressContentSlider {
        height: 50vw;
        margin-bottom: 30px;
    }
}

@media (min-width: 1020px){
    .PressContentSlider {
        height: 26vw;
        margin-bottom: 30px;
    }
}

.PressContentItem {
    text-decoration: none;
    color: inherit;
    max-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.PressContentItem-ImageWrap {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    display: inline-block;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
    /*object-fit: contain;*/
}

.PressContentItem-Image {
    position: absolute;
    display: block;
    height: 100%;
    width: auto;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.PressContentItem_premium .PressContentItem-ImageWrap:before {
    position: absolute;
    content: '';
    display: inline;
    right: 5px;
    bottom: 0;
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.2);
    background-image: url(/static/media/cart.e9366b05.svg);
    background-position: center;
    background-size: 70%;
    background-repeat: no-repeat;
    z-index: 1;
}

.PressContentItem-Title {
    text-align: center;
    position: relative;
    margin-left: 15px;
    font-family: "Lato Regular", Arial, sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 100;
    color: #ffffff;
    letter-spacing: 1px;
}

@media (min-width: 500px) {
    .PressContentItem_premium .PressContentItem-ImageWrap:before {
        right: 0;
    }

}

.PressContentList-Item {
    background-color: #171C23;
    padding-top: 55px;
    position: relative;
    border-top: 5px solid #29313E;
}

.PressContentList-ItemTitle {
    max-width: calc(100% - 40px);
    display: inline-block;
    font-size: 26px;
    line-height: 1;
    padding: 0;
    margin: 0;
    text-align: center;
    background-color: #ffffff;
    font-family: "Lato Light", Arial, sans-serif;
    /*max-width: 745px;*/
    color: #171C23;
    position: absolute;
    top: 19px;
}

.PressContentList-ItemTitle span {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-height: 56px;
    padding: 15px 20px;
}

.PressContentList-ItemTitle:after {
    display: block;
    content: '';
    width: 22px;
    max-width: 22px;
    height: 36px;
    max-height: 36px;
    box-sizing: border-box;
    position: absolute;
    border-top: 18px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 18px solid #404F63;
    border-left: 11px solid #404F63;
    top: 0;
    left: 100%;
    z-index: 1;
}

@media (min-width: 720px) {
    .PressContentList {
        font-family: "Arial Narrow", Arial, sans-serif;
    }

    .PressContentList-ItemTitle {
        font-family: "Arial Narrow Bold", Arial, sans-serif;
    }

}
.CategoryItem {
    display: flex;
    flex-direction: column;
}

.CategoryItem-ContentWrap {
    padding: 0 10px;
}

.CategoryItem-HomeLink_ofPage_main {
    display: none;
}

.CategoryItem-Title {
    margin: 30px 0;
    font-size: 30px;
    text-transform: uppercase;
}

/*разные фоны категорий */

.CategoryItem_theme_dark,
.CategoryItem:nth-child(7) {
    background-color: #272f3b;
}

.CategoryItem:nth-child(even) {
     background-color: #fff;
 }

.CategoryItem:nth-child(3) {
    background-color: #aac613;
}

.CategoryItem:nth-child(5) {
    background-color: #f08c00;
}

.CategoryItem-TitleLink,
.CategoryItem-TitleLink:hover,
.CategoryItem-TitleLink:active {
    text-decoration: none;
    color: #4c4c4e;
    font-family: "Arial Bold" , sans-serif;
    text-align: center;
}

.CategoryItem:nth-child(even) .CategoryItem-TitleLink,
.CategoryItem:nth-child(even) .CategoryItem-TitleLink:hover,
.CategoryItem:nth-child(even) .CategoryItem-TitleLink:active {
    color: #58595b;
}

.CategoryItem:nth-child(odd) .CategoryItem-TitleLink,
.CategoryItem:nth-child(odd) .CategoryItem-TitleLink:hover,
.CategoryItem:nth-child(odd) .CategoryItem-TitleLink:active {
    color: #fff;
}

.CategoryItem_theme_dark .CategoryItem-TitleLink,
.CategoryItem_theme_dark .CategoryItem-TitleLink:hover,
.CategoryItem_theme_dark .CategoryItem-TitleLink:active {
    color: #fff;
}

/*кнопка больше*/

.CategoryItem-MoreButtonWrapper {
    padding: 20px 0 30px;
}

.CategoryItem-MoreButtonLink,
.CategoryItem-MoreButtonLink:hover,
.CategoryItem-MoreButtonLink:active {
    display: block;
    text-align: center;
    text-decoration: none;
    font-family: Arial, sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: inherit;
}

.CategoryItem-MoreButton {
    padding: 20px;
    text-align: center;
    min-width: 220px;
    max-width: 320px;
    width: 70%;
    margin: 0 auto;
}

.CategoryItem:nth-child(even) .CategoryItem-MoreButton {
    background-color: #E30611;
    color: #fff;
}

.CategoryItem:nth-child(odd) .CategoryItem-MoreButton {
    background-color: #fff;
    color: #58595b;
}

/*категория на странице категорий */
.CategoryItem_ofPage_category {
    background-color: #f6f6f6;
}

.CategoryItem_ofPage_category.CategoryItem_theme_dark {
    background-color: #272f3b;
}

.CategoryItem_ofPage_category.CategoryItem_theme_dark .CategoryItem-HomeLink-Btn_size_S {
    /*filter: invert(100%);*/
}

.CategoryItem_ofPage_category .CategoryItem-Title {
    color: #4c4c4e;
}

.CategoryItem_ofPage_category.CategoryItem_theme_dark .CategoryItem-Title {
    color: #ffffff;
}

.CategoryItem_ofPage_category .CategoryItem-MoreButtonWrapper {
    display: none;
}

.CategoryItem-LinkWrap {
    position: relative;
}

/*кнопка домой*/
.CategoryItem-HomeLink_ofPage_category,
.CategoryItem-HomeLink_ofPage_category:hover,
.CategoryItem-HomeLink_ofPage_category:active {
    text-decoration: none;
    display: block;
    height: 30px;
    width: auto;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 1;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.CategoryItem-HomeLink-Btn_size_S {
    height: 30px;
    width: 30px;
    background-image: url(/static/media/back.26438695.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 25px 25px;
}

.CategoryItem-HomeLink-Btn_size_L {
    display: none;
}

@media (min-width: 720px) {
    .CategoryItem-MoreButtonWrapper {
        padding: 10px 0 30px;
    }

    .CategoryItem-LinkWrap {
        max-width: 1280px;
        width: 100%;
        margin: 0 auto;
    }

    .CategoryItem-HomeLink.CategoryItem-HomeLink_ofPage_category {
        display: none;
    }

    .CategoryItem_theme_dark.CategoryItem_ofPage_main .CategoryItem-MoreButtonWrapper {
        padding: 0 0 30px;
    }

    .CategoryItem-HomeLink-Btn_size_S {
        display: none;
    }

    .CategoryItem-HomeLink-Btn_size_L {
        display: block;
        text-transform: lowercase;
        font-family: "Arial Narrow Bold", Arial, sans-serif;
        font-size: 20px;
    }
}


.CategoryPage {
}


.CategoryPage-Content {
    margin-top: 93px;
}


.CloseButton {
    cursor: pointer;
}
.HamburgerButton {
    cursor: pointer;
}
.Menu {
    color: #fff;
    min-width: 240px;
    font-family: "Arial Narrow", Arial, sans-serif;
    font-size: 22px;
    width: 100%;
    padding-top: 93px;
}

.Menu-Wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
 }

.Menu_visible {
    display: block;
}

.Menu-List {
    width: 50%;
    height: 100%;
    list-style: none;
    margin: 0;
    padding: 20px 0 20px 20px;
    background-color: #58595b;
}

.Menu-Link,
.Menu-Link:hover,
.Menu-Link:active {
    display: block;
    text-decoration: none;
    text-align: left;
    padding-left: 35px;
    height: 60px;
    line-height: 60px;
    color: inherit;
    position: relative;
}

.Menu-Item,
.Menu-Item:hover,
.Menu-Item:active {
    position: relative;
}

.Menu-Item_type_pressa-ru-ua-pdf:before {
    background-image: url(/static/media/press.5d4e7f86.svg);
}

.Menu-Item_type_family:before {
    background-image: url(/static/media/family.6e845751.svg);
}

.Menu-Item_type_health:before {
    background-image: url(/static/media/heart.8b46895a.svg);
}

.Menu-Item_type_style:before {
    background-image: url(/static/media/style.49fa5354.svg);
}

.Menu-Item_type_beauty:before {
    background-image: url(/static/media/lips.8679ea13.svg);
}

.Menu-Item_type_diet:before {
    background-image: url(/static/media/meatball.3f6275bb.svg);
}

.Menu-Item_type_sport:before {
    background-image: url(/static/media/gym.3bf85bd5.svg);
}

.Menu-Item:before {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    left: 0;
    content: '';
    display: block;
    width: 25px;
    height: 25px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}

@media (min-width: 1200px) {
    .Menu {
        width: auto;
        height: 60px;
        padding-top: 0;
        margin-left: auto;
        color: #58595b;
        font-size: 20px;
        font-family: "Lato Semibold", Arial, sans-serif;
        text-transform: uppercase;
    }

    .Menu-Wrapper {
        flex-direction: row;
        background-color: transparent;
    }

    .Menu-List {
        display: flex;
        flex-direction: row;
        margin: 0;
        padding: 0;
        height: 70px;
        background-color: transparent;
    }

    .Menu-Item {
        padding: 0 0 0 15px;
    }

    .Menu-Item:before {
        display: none;
    }

    .Menu-Link,
    .Menu-Link:hover,
    .Menu-Link:active {
        height: 70px;
        line-height: 70px;
        padding: 10px 0 0 0;
    }

    .Menu-ItemLink-Text {
        line-height: 50px;
    }

    .Menu-Link:hover .Menu-ItemLink-Text,
    .Menu-Link:active .Menu-ItemLink-Text {
        color: #E30611;
        border-bottom: 5px solid #E30611;
    }
}

.Header {
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    height: 93px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Header-Wrapper {
    height: 100%;
    display: flex;
    align-items: center;
}

.Header-Logo {
    max-height: 40px;
}

.Header-LogoContainer {
    margin-right: 20px;
}

.Header-ButtonWrapper {
    position: relative;
    height: 100%;
    width: 50px;
    min-width: 50px;
}

.Header-Select {
    cursor: pointer;
    position: relative;
    margin-left: auto;
    margin-right: 20px;
    width: 60px;
}

.Header-SelectList {
   display: none;
    padding: 0;
    list-style-type: none;
}

.Header-SelectList_visible {
    position: absolute;
    top: 100%;
    display: block;
    margin: 0;
    background-color: #fff;
    width: 60px;
}

.Header-SelectItem {
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    text-align: left;
    padding: 0 30px 0 10px;
}

.Header-SelectItem span {
    font-size: 16px;
}

.Header-SelectItem_state_selected {
    background-image: url(/static/media/down-arrow.61658679.svg);
    background-repeat: no-repeat;
    background-position: 85% 50% ;
}

.Header-SelectItem_active {
    display: none;
}


.Header-HamburgerButton,
.Header-CloseButton {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 10;
    opacity: 0;
    -webkit-transition:  all cubic-bezier(0.17,0.67,1,-0.1) 0.5s;
    transition:  all cubic-bezier(0.17,0.67,1,-0.1) 0.5s;
}

.Header-HamburgerButton_visible,
.Header-CloseButton_visible {
    opacity: 1;
}

.Header-Menu {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    min-height: 100%;
    z-index: 1;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    opacity: 0;
    -webkit-transition: opacity ease-in-out 0.8s, -webkit-transform ease-in-out 0.2s;
    transition: opacity ease-in-out 0.8s, -webkit-transform ease-in-out 0.2s;
    transition: opacity ease-in-out 0.8s, transform ease-in-out 0.2s;
    transition: opacity ease-in-out 0.8s, transform ease-in-out 0.2s, -webkit-transform ease-in-out 0.2s;
}

.Header-Menu_visible {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
}

@media (min-width: 720px) {
    .Header-Wrapper {
        max-width: 1280px;
        margin: 0 auto;
    }
}

@media (min-width: 1200px) {
    .Header-Menu,
    .Header-Menu_visible {
        position: relative;
        -webkit-transform: translateX(0);
                transform: translateX(0);
        height: auto;
        top: auto;
        bottom: auto;
        left: auto;
        opacity: 1;
    }

    .Header-Select {
        margin-left: 30px;
    }

    .Header-ButtonWrapper {
        display: none;
    }

    .Header-LogoContainer {
        margin-left: 20px;
    }
}

.Header-PersonalCabinet {
    width: 100%;
    max-width: 1280px;
    font-size: 12px;
    padding: 5px 10px;
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px #4c4c4e solid;
}

.Header-PersonalCabinet a {
    color: #4c4c4e;
    text-decoration: underline;
}
.ContentAdditional {
}

@media (min-width: 720px) {
    .ContentAdditional-ContentItem {
        margin-bottom: 20px;
    }

    .ContentAdditional {
        background-color: #f6f6f6;
        color: #fff;
        padding: 10px 20px;
    }
}

.ContentText {
    text-align: justify;
    font-size: 14px;
    line-height: 20px;
    font-family: "Lato Light", Arial, sans-serif;
    color: #000000;
    margin-bottom: 20px;
}

.ContentText p,
.ContentText ul {
    font-family: "Lato Regular", Arial, sans-serif;
}

.ContentText p:first-child {
    font-family: "Lato Light", "Arial Narrow", sans-serif;
}

.ContentText h2, h3 {
    font-family: "Lato Heavy", "Arial Bold", sans-serif;
    font-size: 24px;
    line-height: 30px;
    margin-left: 20px;
}

.ContentText h3 {
    font-size: 20px;
}

.ContentText h1,
.ContentText h2,
.ContentText h3,
.ContentText h4,
.ContentText h5 {
    text-align: left;
}

.ContentText li {
    list-style: none;
    position: relative;
    padding-left: 20px;
    padding-bottom: 10px;
    text-align: left;
}

.ContentText li:before {
    display: block;
    content: '';
    width: 6px;
    height: 6px;
    position: absolute;
    top: 10px;
    left: -5px;
    border-radius: 3px;
    background-color: #f5d300;
}

.ContentText p,
.ContentText i {
    text-indent: 20px;
    margin-bottom: 1em;
    display: block;
}

.ContentText img,
.ContentText video {
    display: block;
    max-width: 100%;
    height: auto;
    margin-top: 20px;
    width: 100%;
}

.ContentText p img {
    margin-bottom: 20px;
}

.ContentText video {
    margin-bottom: 20px;
}


@media (min-width: 720px) {
    .ContentText {
        font-size: 20px;
        line-height: 30px;
        color: #666;
        font-family: Arial, sans-serif;
        margin-bottom: 40px;
    }

    .ContentText p,
    .ContentText ul {
        font-family: inherit;
    }
}
.ErrorPage {
    text-align: center;
}

.ErrorPage-LogoContainer {
    background-color: #592e83;
    height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    display: flex;
    align-items: center;
}

.ErrorPage-LogoContainer {
    padding-left: 20px;
}

.ErrorPage-Logo {
    max-height: 40px;
}


.ErrorPage-Title {
    margin: 202px 0 20px;
    text-align: center;
}

.ErrorPage-Title span {
    display: block;
}

.ErrorPage-Link,
.ErrorPage-Link:hover,
.ErrorPage-Link_active {
    display: block;
    text-decoration: none;
    width: 170px;
    font-size: 24px;
    line-height: 50px;
    background-color: #f5d300;
    background-image: none;
    border-radius: 25px;
    text-align: center;
    margin: 0 auto 100px;
    color: #4c4c4e;
}

@media (min-width: 720px) {
    .ErrorPage {
        font-family: "Arial Narrow", Arial, sans-serif;
    }

    .ErrorPage * {
        font-family: inherit;
    }

}

.preloader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 120px;
    padding-bottom: 50px;
    z-index: 10000;
    box-sizing: border-box;
}

.preloader--slider-item {
    padding-top: 43vh;
}

.preloader--fullscreen {
    position: fixed;
    padding: 0;
    background-color: #ffffff;
}

.preloader--vertical {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.ContentPage {
}

.ContentPage-Content {
    margin-top: 93px;
}

.ContentPage-MainBar {
    padding: 0 10px;
}

.ContentPage-CategoryLink {
    height: 40px;
    min-width: 40px;
    color: inherit;
    text-decoration: none;
    display: inline-block;
    padding: 15px 0;
    text-align: left;
    position: relative;
}

.ContentPage-CategoryLink-Btn {
    text-decoration: none;
    display: block;
    height: 30px;
    width: 30px;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px 30px;
}

.ContentPage-CategoryLink-Btn_size_L {
    display: none;
}

.ContentPage-CategoryLink-Btn_size_S {
    background-image: url(/static/media/back.26438695.svg);
}

.ContentPage-AdditionalBar {
    padding: 0 10px;
    background-color: #f6f6f6;
}

.ContentPage-Video {
    max-width: 100%;
    margin-bottom: 20px;
}

.ContentPage-Video[poster] {
    object-fit: cover;
}

.ContentPage-ContentAdditionalWrapper {
    display: flex;
    flex-direction: column;
}

.ContentPage-ContentAdditionalTitle {
    text-align: center;
    margin: 30px 0;
}

@media (min-width: 720px) {
    .ContentPage-Content {
        display: flex;
    }

    .ContentPage-Video {
        width: 96%;
        margin: 0 2% 20px;
    }

    .ContentPage-MainBar {
        width: 67vw;
        padding: 0 40px;
    }

    .ContentPage-AdditionalBar {
        overflow-y: scroll;
        position: relative;
        align-self: stretch;
        width: 33vw;
    }

    .ContentPage-ContentAdditionalWrapper {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
    }

    .ContentPage-ContentAdditionalTitle {
        display: none;
    }

    .ContentPage-CategoryLink {
        height: 90px;
        min-width: 90px;
    }

    .ContentPage-CategoryLink-Btn {
        height: 80px;
        width: 50px;
        position: absolute;
        top: 50%;
        left: 0;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 50px 50px;
    }

    .ContentPage-CategoryLink-Btn_size_L {
        display: block;
    }

    .ContentPage-CategoryLink-Btn .ContentPage-CategoryLink-Btn_size_S {
        display: none;
    }

}

.Footer {
    font-family: "Lato Light", Arial, sans-serif;
    border-top: 20px solid #333333;
    background-color: #58595b;
    color: #adafaf;
    padding: 20px 10px;
}

.Footer-OfferLink,
.Footer-OfferLink:hover,
.Footer-OfferLink:active,
.Footer-SubscriptionLink,
.Footer-SubscriptionLink:hover,
.Footer-SubscriptionLink:active {
    text-transform: uppercase;
    text-decoration: underline;
    color: inherit;
    display: block;
    margin: 10px 0;
}

.Footer p {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
}

.Footer-CategoriesLinkWrap {
    padding: 20px 0;
    font-family: "Lato Light", Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Footer-Link,
.Footer-Link:hover,
.Footer-Link:active {
    color: inherit;
    font-size: 20px;
    line-height: 40px;
    height: 40px;
    width: 220px;
    text-decoration: none;
    margin-bottom: 10px;
    border: 1px solid #adafaf;
    text-align: center;
}

.Footer-Link,
.Footer-Link:active {
    border: 1px solid #adafaf;
}

.Footer-Link:hover {
    border: 1px solid #E30611;
    color: #E30611;
}

.Footer .TextLink,
.Footer .TextLink:hover,
.Footer .TextLink:active,
.Footer-Text a,
.Footer-Text a:hover,
.Footer-Text a:active {
    display: inline;
    color: inherit;
}

.Footer-Text {
    text-align: justify;
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
}

.Footer-LogoContainer {
    display: flex;
    justify-content: center;
}
.Footer-LogoLink,
.Footer-LogoLink:hover,
.Footer-LogoLink:active {
    height: 18px;
    margin-right: 10px;
    -webkit-filter: opacity(0.3);
            filter: opacity(0.3);
}

.Footer-Logo {
    height: 18px;
}

.Footer .HomeLink {
    text-decoration: none;
}

@media (min-width: 470px) {
    .Footer-CategoriesLinkWrap {
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
    }

    .Footer-CategoryLink {
        margin-right: 5px;
        margin-left: 5px;
    }

}

@media (min-width: 720px) {
    .Footer {
        font-family: "Arial Narrow", Arial, sans-serif;
    }

    .Footer * {
        font-family: inherit;
    }

    .Footer-Wrapper {
        max-width: 1280px;
        margin: 0 auto;
    }

}

@media (min-width: 1080px) {
    .Footer-Link,
    .Footer-Link:hover,
    .Footer-Link:active {
        width: 140px;
    }
}

.CategoryList {

}


.MainPage {

}

.MainPage-Content {
    margin-top: 93px;
}


.OfferPage {
    flex-grow: 1;
}

.OfferPage-Text a{
    color: inherit;
    display: inline;
}

.OfferPage-Text {
    text-align: justify;
    margin-top: 93px;
    padding: 10px;
    font-family: "Lato Light", Arial, sans-serif;
}

.OfferPage-Text h2 {
    font-family: "Lato Heavy", Arial, sans-serif;
    text-align: left;
}

.OfferPage-Text li {
    margin-bottom: 10px;
}

.OfferPage-Text div {
    margin-left: 20px;
    margin-bottom: 10px;
}

@media (min-width: 720px) {
    .OfferPage-Text {
    }
}

.PressContentPage {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: #29313E;
    position: relative;
}

.PressContentPage .preloader {
    height: 69vh;
}

@media(min-height: 800px) {
    .PressContentPage .preloader {
        height: 59vh;
    }
}

.PressContentPage-ContentWrapper {
    height: calc(100vh - 142px);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-top: 105px;
}

.PressContentPage * {
    box-sizing: border-box;
}

.PressContentPage-Item {
    background-color: #29313E;
    flex-grow: 1;
    display: flex;
    max-height: 70px;
    min-height: 40px;
}

.PressContentPage-ItemTitleWrapper {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px 10px 60px;
    color: #fff;
    font-size: 20px;
    position: relative;
}

.PressContentPage-ItemDate {
    position: relative;
    white-space: nowrap;
}

.PressContentPage-ItemDate_hideSymbol:after {
    display: block;
    content: '';
    height: 26px;
    width: 12px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    background-color: #29313E;
}

.PressContentPage-CategoryLink,
.PressContentPage-CategoryLink:hover,
.PressContentPage-CategoryLink:active {
    text-decoration: none;
    display: block;
    height: 30px;
    width: 30px;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 1;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background-color: #ffffff;
    background-image: url(/static/media/back.26438695.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 25px 25px;
}

.PressContentPage-SwiperWrapper {
    position: relative;
    margin-bottom: auto;
}

.PressContentPage-Paginator {
    color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    font-weight: normal;
    font-size: 20px;
    margin: 0;
    height: auto;
    padding: 10px 20px 10px 5px;
    line-height: 30px;
    background-color: rgba(41, 49, 62, 0.5);
    padding-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.PressContentPage-CurrentPageInput {
    height: 30px;
    width: 45px;
    background-color: #fff;
    color: #29313E;
    text-align: center;
    display: inline-block;
    border-radius: 5px;
    margin: 0 5px;
    font-size: inherit;
    font-family: inherit;
    outline: none;
    cursor: pointer;
}

.PressContentPage-PaginatorText {
    margin: 0 8px;
}

.PressContentPage .slide {
    width: auto;
    height: calc(100% - 50px);
    background-color: #29313E;
    min-height: calc(100% - 50px);
}

.PressContentPage .swiper-button-next,
.PressContentPage .swiper-button-prev {
    background-color: rgba(41, 49, 62, 0.5);
    top: 44%;
}

.PressContentPage .swiper-container,
.PressContentPage .swiper-wrapper {
    min-height: 100%;
}

.PressContentPage .swiper-button-prev {
    -webkit-transform: scale(-1, 1);
            transform: scale(-1, 1);
}

.PressContentPage-Image {
    display: block;
    max-width: 100vw;
    margin: 0 auto;
    width: auto;
    height: auto;
}

@media(min-width: 720px) {
    .PressContentPage {
        position: static;
    }
    .PressContentPage-ContentWrapper{
        margin-top: 102px;
        height: calc(100vh - 102px);
    }

    .PressContentPage-Item {
        width: 720px;
        margin: 0 auto;
    }

    .PressContentPage-SwiperWrapper {
        position: static;
    }

    .PressContentPage-Image {
        max-width: 720px !important;
        height: auto;
        width: 720px;
    }


}
.App {
    min-width: 320px;
    /*max-width: 1320px;*/

    /*!*margin: 0 auto;*!*/
    /*min-height: 100vh;*/
}

.App-Wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

@media (min-width: 720px) {
    .App {
        /*font-family: 'Arial Narrow', Arial, sans-serif;*/
    }
}
.SubscriptionPage {
    margin-top: 93px;
    padding: 10px;
    font-family: "Lato Light", Arial, sans-serif;
    flex-grow: 1;
    display: flex;
}

.SubscriptionPage-Text {
    flex-grow: 1;
    max-width: 1300px;
    margin: 0 auto;
}

.SubscriptionPage-Text a,
.SubscriptionPage-Text a:hover,
.SubscriptionPage-Text a:active {
    display: inline;
    color: inherit;
}

@media (min-width: 720px) {
    .SubscriptionPage {
        font-size: 20px;
    }
}
@font-face {
  font-family: "Lato Heavy";
  font-style: normal;
  font-weight: 700;
  src: local("Lato Heavy"), url(/static/media/Lato-Heavy.5baa1f3b.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Lato Light";
  font-style: normal;
  font-weight: 300;
  src: local("Lato Light"), url(/static/media/Lato-Light.94e07077.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Lato Regular";
  font-style: normal;
  font-weight: 400;
  src: local("Lato Regular"), url(/static/media/Lato-Regular.ca14b588.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Lato Semibold";
  font-style: normal;
  font-weight: 400;
  src: local("Lato Semibold"), url(/static/media/Lato-Semibold.b646f1ff.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Arial Narrow";
  font-style: normal;
  font-weight: 400;
  src: local("Arial Narrow"), url(/static/media/Arial-Narrow.482a5383.woff2) format("woff2");
  font-display: swap;
}


@font-face {
  font-family: "Arial Narrow Bold";
  font-style: normal;
  font-weight: 400;
  src: local("Arial Narrow Bold"), url(/static/media/Arial-Narrow-Bold.04631ab3.woff2) format("woff2");
  font-display: swap;
}

* {
  -webkit-tap-highlight-color: transparent !important;
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
}

body {
  padding: 0;
  font-family: "Lato Regular", Arial, sans-serif;
  line-height: 1;
  overflow-x: hidden;
  min-width: 320px;
  color: #4c4c4e;
  margin: 0 auto;
  position: relative;
}

button,
a {
  cursor: pointer;
  display: block;
}

a {
  text-decoration: none;
}

.Wrapper {
  max-width: 1280px;
  width: 100%;
}

/*swiper*/
.swiper-button-next,
.swiper-button-prev {
  background-image: url(/static/media/NextArrowRed.64aa6521.svg);
  width: 60px;
  height: 60px;
  background-position: center;
  border-radius: 30px;
  cursor: pointer;
  outline: none;
}

.swiper-button-prev {
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1);
}

@media (min-width: 720px) {
  body {
    font-family: "Arial Narrow", Arial, sans-serif;
  }
}



